import { Container } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { Header } from './components/Header';
import { routes } from './router/Routes';


function App() {
  const routeElement = useRoutes(routes);

  return (
    <Container>
      <Header />
      {routeElement}
    </Container>
  );
}

export default App;
