import { Box } from '@mui/system';
import Grid from '@mui/material/Unstable_Grid2'
import { BasePage } from '../components/Pages';
import { CardContainer, GridContainer } from '../components/Containers';
import { useEffect } from 'react';

export function Services() {
  useEffect(() => {
    document.title = 'Services';
  }, []);

  return (
    <BasePage>
      <GridContainer sx={{ alignItems: 'stretch' }}>
        <Grid xl={6} lg={6} md={6} sm={12} xs={12}>
          <CardContainer title='Evaluations' sx={{ height: '100%' }}>
            <ul>
              <li>2-hour time block (we may not need the full time)</li>
              <li>Includes formal and informal measures, feeding observation, oral mechanism examination, and parent interview.</li>
              <li>You will receive a report within 1 week including analysis of the assessment, a diagnosis, and recommendations for therapy.</li>
            </ul>
          </CardContainer>
        </Grid>
        <Grid xl={6} lg={6} md={6} sm={12} xs={12}>
          <CardContainer title='Treatment' sx={{ height: '100%' }}>
            <ul>
              <li>1-hour sessions</li>
              <li>1 or 2 times per week</li>
              <li>Informal progress check-in every 8 sessions to determine if/how much therapy is still needed.</li>
            </ul>
          </CardContainer>
        </Grid>
        <Grid xl={6} lg={6} md={6} sm={12} xs={12}>
          <CardContainer title='Areas of Service' sx={{ height: '100%' }}>
            <Box sx={{ textAlign: 'center', marginTop: '8px' }}>
              While I would love to serve every client possible, there are some limitations regarding the distance I can travel. Please note the following areas that I serve:
            </Box>

            <ul>
              <li>Pasadena</li>
              <li>South Pasadena</li>
              <li>San Marino</li>
              <li>Sierra Madre</li>
              <li>Altadena</li>
              <li>La Canada-Flintridge</li>
              <li>Arcadia</li>
              <li>East San Gabriel </li>
              <li>Highland Park</li>
            </ul>
          </CardContainer>
        </Grid>
      </GridContainer>
    </BasePage>
  );
};

