import Grid from '@mui/material/Unstable_Grid2'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Chip, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { GridContainer } from '../components/Containers';
import { PaperPage } from '../components/Pages';

export function Contact() {
  const defaultCopyEmailComponent = (<Box>Copy Email</Box>);
  const athenaEmail = 'athena@berraspeechtherapy.com';

  const [copyEmailComponent, updateCopyEmailComponent] = useState(defaultCopyEmailComponent);
  const [resetEmailTextTimeout, updateResetEmailTextTimeout] = useState<any>(null);

  useEffect(() => {
    document.title = 'Contact';
  }, []);

  const copyEmailToClipboard = () => {
    navigator.clipboard.writeText(athenaEmail);
    updateCopyEmailComponent(
      <Box>
        Copied! <FontAwesomeIcon icon={faCircleCheck} style={{ color: '#ff8080', }} />
      </Box>
    );

  };

  const onToolTipClose = () => {
    if (copyEmailComponent !== defaultCopyEmailComponent) {
      updateResetEmailTextTimeout(
        window.setTimeout(
          () => {
            updateCopyEmailComponent(defaultCopyEmailComponent);
            updateResetEmailTextTimeout(null);
          },
          500
        )
      );
    }
  };

  const onToolTipOpen = () => {
    if (resetEmailTextTimeout) {
      window.clearTimeout(resetEmailTextTimeout);
    }
  };

  return (
    <PaperPage>
      <GridContainer>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} sx={{ textAlign: 'center' }}>
          Rates are based on the current, going rate for speech-language services in the area, and are subject to change. Rates will be provided upon inquiry.
        </Grid>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} sx={{ textAlign: 'center' }}>
          Contact form is coming soon! In the meantime, please send inquiries to the email below.
        </Grid>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Tooltip
            title={copyEmailComponent}
            placement='right'
            onClose={onToolTipClose}
            onOpen={onToolTipOpen}
          >
            <Chip label={athenaEmail} onClick={copyEmailToClipboard} />
          </Tooltip>
        </Grid>
      </GridContainer>
    </PaperPage>
  );
};

