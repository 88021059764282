import { Theme } from '@emotion/react';
import { Card, SxProps, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'
import { ReactNode } from 'react';

interface GridProps {
  children: ReactNode;
  sx?: SxProps<Theme>;
};

export function GridContainer(props: GridProps) {
  return (
    <Grid
      container
      spacing={8}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ...props.sx }}
    >
      {props.children}
    </Grid>
  );
};

interface CardProps {
  title?: string;
  children: ReactNode;
  sx?: SxProps<Theme>;
  outlined?: boolean;
};

export function CardContainer(props: CardProps) {
  return (
    <Card
      sx={{
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...props.sx
      }}
      variant={props.outlined ? 'outlined' : 'elevation'}
    >
      {props.title ?
        <Typography
          variant='h4'
          sx={{
            width: 'fit-content',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          {props.title}
        </Typography>
        :
        null
      }

      {props.children}
    </Card>
  );
};

