import { useState } from 'react';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { Tab, Tabs } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

interface LinkTabProps {
  label?: string;
  href?: string;
  selected?: boolean;
}

function samePageLinkNavigation(
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
) {
  if (
    event.defaultPrevented ||
    event.button !== 0 || // ignore everything but left-click
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  ) {
    return false;
  }
  return true;
}


function LinkTab(props: LinkTabProps) {
  const navigate = useNavigate();


  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        // Routing libraries handle this, you can remove the onClick handle when using them.
        if (samePageLinkNavigation(event)) {
          event.preventDefault();
          if (props.href) {
            navigate(props.href);
          }
        }
      }}
      aria-current={props.selected && 'page'}
      {...props}
    />
  );
}

// TODO: There is certainly a better way to do this - do that
const tabPathIndices: Array<string> = [
  '/',
  '/about',
  '/services',
  '/testimonials',
  '/contact'
];

// TODO: Not the most eligant solution - try to determin a better way to go about this
function GetPageIndex() {
  const location = useLocation();

  const pathIndex = tabPathIndices.indexOf(location.pathname);
  if (pathIndex > -1) {
    return pathIndex;
  } else {
    return null;
  }
}

export function Header() {
  const [selectedTab, setSelectedTab] = useState(GetPageIndex());

  const handleTabSelection = (event: React.SyntheticEvent, newValue: number) => {
    // event.type can be equal to focus with selectionFollowsFocus.
    if (
      event.type !== 'click' ||
      (event.type === 'click' &&
        samePageLinkNavigation(
          event as React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        ))
    ) {
      setSelectedTab(newValue);
    }
  };

  return (
    <Box m={8}>
      <Typography
        variant="h3"
        sx={{
          width: 'fit-content',
          marginLeft: 'auto',
          marginRight: 'auto',
          textAlign: 'center'
        }}
      >
        Berra Speech Therapy
      </Typography>
      <Box m={4} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabSelection}
          variant='scrollable'
          scrollButtons='auto'
        >
          <LinkTab label='Home' href='/' />
          <LinkTab label='About Me' href='/about' />
          <LinkTab label='Services' href='/services' />
          <LinkTab label='Testimonials' href='/testimonials' />
          <LinkTab label='Contact' href='/contact' />
        </Tabs>
      </Box>
    </Box>
  );
}

