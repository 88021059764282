import { Box } from "@mui/system";
import { RouteObject } from "react-router-dom";
import { PaperPage } from "../components/Pages";
import { About } from "../pages/About";
import { Contact } from "../pages/Contact";
import { Home } from "../pages/Home";
import { Services } from "../pages/Services";
import { Testimonials } from "../pages/Testimonials";

export const routes: RouteObject[] = [
  {
    // TODO: Add in an error page of some sort
    errorElement: <RouteError />,
    path: '/',
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: '/about',
        element: <About />
      },
      {
        path: '/services',
        element: <Services />
      },
      {
        path: '/testimonials',
        element: <Testimonials />
      },
      {
        path: '/contact',
        element: <Contact />
      },
      {
        path: '*',
        element: <RouteError />
      }
      // TODO: Add in a catch all to the route object when users try to navigate to unrecognized routes
    ]
  },
];

function RouteError() {
  return (
    <PaperPage>
      <Box sx={{ textAlign: 'center' }}>
        Hmm... we can't seem to find what you're looking for!
      </Box>
    </PaperPage>
  );
}

