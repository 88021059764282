import Grid from '@mui/material/Unstable_Grid2'
import { Box } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import { CardContainer, GridContainer } from '../components/Containers';
import { PaperPage } from '../components/Pages';
import { useEffect } from 'react';

import athenaAndClientAm1 from '../images/me_and_client_a_m.webp';
import athenaAndClientAf1 from '../images/me_and_a_f_1.webp';
import athenaAndClientAf2 from '../images/me_and_client_a_f_2.webp';
import athenaAndClient1 from '../images/me_and_client.webp';
import athenaAndClient2 from '../images/me_and_client_2.webp';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../styles/carousel.css';

interface Testimonial {
  content: string,
  author?: string,
  date: Date
}

export function Testimonials() {
  // TODO: Make this an api call so it can be updated without redoplying the app
  const scrollIntervalMs = 10000;
  const testimonials: Array<Testimonial> = [
    {
      content: "We have had a great experience with Miss Athena as our son's SLP. We worked with Miss Athena for about 6 months, and my son loves her so much. My son really enjoys each session and he is doing so great and improving his speech very much! My son's latest report was revealing his communication skill is at his peer group/age level. We are so grateful and our joy is absolutely beyond measure. We can't thank Miss Athena enough, and seeking early intervention is the best decision we have ever made.",
      author: "Jeni S.",
      date: new Date("2022-07-22")
    },
    {
      content: "I cannot recommend Athena enough for her exceptional work as a speech therapist for children. One of Athena's greatest strengths is her ability to create a warm and nurturing environment. She established a very strong rapport with us and fostered trust and confidence which have been essential for the progression of our child's therapy. Through her patience, kindness, and unwavering dedication, she empowered us as parents to reinforce speech and language goals outside of therapy sessions. She is a true professional whose expertise, compassion, and dedication make her an invaluable asset to any child in need of speech therapy.",
      author: "Marisol V.",
      date: new Date("2024-03-25")
    },
    {
      content: "Athena is lovely and effective. I enjoyed attending the sessions just to learn things from her; our son's confidence in speaking and stamina to pronounce things properly increased dramatically.",
      author: "Kristen A.",
      date: new Date("2023-04-14")
    },
    {
      content: "We had a great speech therapy time with Miss Athena for our 2-year-old daughter! Not only was Athena nice and effective in her speech therapy treatment, but also she went above and beyond to help our daughter in developing her language skills!! We highly recommend Miss Athena to everyone who is in need of speech therapy for their little ones! Thank you very very much Miss Athena!!",
      author: "Diem B.",
      date: new Date("2023-08-16")
    },
    {
      content: "I could not recommend a children's speech therapist more highly. I did not know what to expect when we began our sessions with our language pathologist, Athena. What I found was someone who was profoundly patient and empathetic with our daughter. Over a year's time, our daughter went from essentially non-verbal to being a little chatterbox able to express herself in an age-appropriate manner. We were ecstatic with her progress. Thanks, Athena. You helped our kid to find her voice, literally.",
      author: "Siwatu M.",
      date: new Date("2023-01-15")
    }
  ];
  const imageCarouselSource = [
    athenaAndClientAm1,
    athenaAndClientAf1,
    athenaAndClientAf2,
    athenaAndClient1,
    athenaAndClient2
  ];

  useEffect(() => {
    document.title = 'Testimonials';
  }, []);

  return (
    <PaperPage>
      <GridContainer sx={{ alignItems: 'stretch' }}>
        <Grid xl={8} lg={8} md={8} sm={12} xs={12}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
          }}>
            <Carousel
              autoPlay={true}
              stopOnHover={true}
              infiniteLoop={true}
              interval={scrollIntervalMs}
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              showIndicators={false}
              className="carousel-container"
            >
              {testimonials.map((testimonial, i) => {
                return (
                  <CardContainer outlined={true} key={i}>
                    <Box
                      sx={{
                        textAlign: 'left',
                        fontStyle: 'italic',
                        marginBottom: '8px'
                      }}
                    >
                      "{testimonial.content}"
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        textAlign: 'right'
                      }}
                    >
                      - {testimonial.author} {testimonial.date.toLocaleDateString()}
                    </Box>
                  </CardContainer>
                );
              })}
            </Carousel>
          </Box>
        </Grid>
        <Grid xl={4} lg={4} md={4} sm={12} xs={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%'
            }}
          >
            <Carousel
              autoPlay={true}
              stopOnHover={true}
              infiniteLoop={true}
              interval={scrollIntervalMs * 2}
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              showIndicators={false}
            >
              {imageCarouselSource.map((source, i) => {
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                    }}
                    key={i}
                  >
                    <img
                      src={source}
                      alt='Athena Kavounas with Client'
                      key={i}
                    />
                  </Box>
                );
              })}
            </Carousel>
          </Box>
        </Grid>
      </GridContainer>
    </PaperPage>
  );
}

